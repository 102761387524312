<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <v-select
          v-model="form.channel_id"
          label="Channel"
          :items="channelOptions"
          item-text="title"
          item-value="value"
          :disabled="!!resource || !!parent"
          outlined
        ></v-select>

        <v-select
          v-model="form.distributor_id"
          label="Distributor"
          :items="distributorOptions"
          item-text="label"
          item-value="value"
          :disabled="!parent"
          outlined
        ></v-select>

        <v-text-field
          v-model="form.key"
          label="Key"
          placeholder="services.stripe.key"
          hint="services.stripe.key"
          outlined
          :rules="parent ? [] : [required]"
          :disabled="!!resource || !!parent"
        ></v-text-field>

        <v-text-field
          v-model="form.value"
          label="Value"
          placeholder="pk_prod_t19Ie......."
          hint="When you update, the value will be encrypted."
          outlined
          :rules="[required]"
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { useDistributor } from '@/composables'
import channelStore from '@/modules/channel/store'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
    parent: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, defValue) => ({
      [name]: props.resource ? props.resource[name] : defValue,
    })

    const initialForm = {
      ...field('key', undefined),
      ...field('channel_id', props.parent?.channel_id || null),
      ...field('parent_id', props.parent?.id || null),
      ...field('distributor_id', props.parent?.distributor_id || null),
      value: '',
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const { distributorOptions, fetchDistributors } = useDistributor()

    if (initialForm.channel_id) {
      fetchDistributors({
        channel_id: initialForm.channel_id,
        take: 100,
      })
    }

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const channelOptions = computed(() => channelStore.state.channels.map(ch => ({ title: `${t(ch.name)} (${ch.code})`, value: ch.id })))

    return {
      form,
      formElem,

      validate,
      required,

      channelOptions,
      distributorOptions,
    }
  },
}
</script>
